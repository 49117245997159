<template>
  <section>
    <x1 />
  </section>
</template>

<script>
  export default {
    metaInfo () {
      return {
        title: 'X1'
      }
    },

    components: {
      X1: () => import('@/components/X1s.vue')
    }
  }
</script>
